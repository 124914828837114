import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { E401Component } from '@app/components/E401Component/e401.component';
import { E404Component } from '@app/components/E404Component/e404.component';
import { ErrorNotFoundComponent } from '@app/error-not-found/error-not-found.component';
import { AuthGuard } from '@app/Gaurds/auth-gaurd.guard';
import { HomeComponent } from '@app/home/home.component';
import { PartsExpertComponent } from '@app/parts-expert/parts-expert.component';
import { PreloadingServiceService } from '@app/preloading-service.service';
import { SysRole } from '@app/services/models/roles';
import { UploadPricingComponent } from '@app/upload-pricing/upload-pricing.component';
import { NewUserLandingPageComponent } from './new-user-landing-page/new-user-landing-page.component';

const routes: Routes = [

  {
    path: 'estimate',
    loadChildren: () => import('../app/estimate/estimate.module').then(module => module.EstimateModule),
    canActivate: [AuthGuard],
    data: {preload: true}
  },
  {
    path: 'templates',
    loadChildren: () => import('../app/templates/templates.module').then(module => module.TemplatesModule)
  },
  {
    path: 'tco',
    loadChildren: () => import('../app/tco/tco.module').then(module => module.TcoModule),
    canActivate: [AuthGuard],
    data: {preload: true}
  },
  {
    path: 'quick-estimator',
    loadChildren: () => import('../app/quick-estimator-new/quick-estimator-new.module').then(module => module.QuickEstimatorNewModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../app/settings/settings.module').then(module => module.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'global-settings',
    loadChildren: () => import('../app/global-settings/global-settings.module').then(module => module.GlobalSettingsModule),
    canActivate: [AuthGuard],
    data: { component: 'global-settings' }
  },
  {
    path: 'admin',
    loadChildren: () => import('../app/admin/admin.module').then(module => module.AdminModule),
    canActivate: [AuthGuard],
    data: { role: SysRole.Admin }
  },
  {
    path: 'profile',
    loadChildren: () => import('../app/profile/profile.module').then(module => module.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'aboutus',
    loadChildren: () => import('../app/aboutus/aboutus.module').then(module => module.AboutUsModule)
  },
  {
    path: 'general-exception',
    component: E404Component
  },
  {
    path: '401',
    component: E401Component
  },
  {
    path: 'contactus',
    loadChildren: () => import('../app/contactus/contactus.module').then(module => module.ContactUsModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('../app/footer-components/privacy.module').then(module => module.PrivacyModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {title: 'Home Page'}
  },
  {
    path: 'newuser',
    component: NewUserLandingPageComponent,
  },
  {
    path: 'release-notes',
    loadChildren: () => import('../app/release-notes/release-notes.module').then(module => module.ReleaseNotesModule)
  },
  {
    path: 'quickestimator-settings',
    loadChildren: () => import('../app/settings/settings.module').then(module => module.SettingsModule),
    canActivate: [AuthGuard],
  }, {
    path: 'quickEstimator-settings',
    component: UploadPricingComponent
  },
  {
    path: 'landing-page',
    component: PartsExpertComponent,
    data: {title: 'Parts Expert'}
  },
  {
    path: 'page-not-found',
    component: ErrorNotFoundComponent
  },
  {
    path: 'MPestimate',
    loadChildren: () => import('../app/multiple-product-estimate/estimate-details/estimate-details.module').then(module => module.EstimateDetailsModule),
    canActivate: [AuthGuard],
    data: {preload: true}
  },
  {
    path: '',
   redirectTo: '/home', pathMatch: 'full'
  },


  {
    path: '**',
    redirectTo: '/page-not-found', pathMatch: 'full'
  },

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', preloadingStrategy: PreloadingServiceService
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
