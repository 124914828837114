import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { fromEvent, Subject } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { DateDifferencePipe } from "@app/common/pipes/date-difference.pipe";
import { FormatCellPipe } from "@app/common/pipes/format-cell.pipe";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { CcModal_ } from "@app/components/modal/modal.component.service";
import { CcModal } from "@cat-digital-workspace/shared-ui-core/modal";
import { ViewReportOptionsComponent } from "@app/estimate/view-report-options/view-report-options.component";
import { CommonService } from "@app/services/common/common.service";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { UserService } from "@app/services/misc/user.service";
import { DefinitionTypes } from "@app/services/models/misc/general";
import * as AppConstant from "@app/services/shared/app-constants";
import * as appConstants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";
import { ResizeEvent } from "angular-resizable-element";
import { ProgressIndicatorComponent } from "../progress-indicator/progress-indicator.component";
import { MultipleProductService } from "@app/services/multiple-product/multiple-product.service";

export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: "[appSortable]",
})
export class NgbdSortableHeaderV2Directive {
  @Input() appSortable: string;
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();
  @HostBinding("class.asc") dir = this.direction;

  @HostListener("click") rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.appSortable, direction: this.direction });
  }
}

@Component({
  selector: "app-data-table-v2",
  templateUrl: "./data-table-v2.component.html",
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
  styleUrls: ["./data-table-v2.component.scss"],
})
export class DataTableV2Component implements OnInit {
  @ViewChildren(NgbdSortableHeaderV2Directive)
  headers: QueryList<NgbdSortableHeaderV2Directive>;
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;
  @ViewChild(MatTable, { read: ElementRef, static: true })
  private matTableRef: ElementRef;
  rowData;
  @ViewChild(MatTable) set table(matTable: MatTable<any>) {
    if (matTable) {
      this.zone.onMicrotaskEmpty
        .pipe(take(3))
        .subscribe(() => matTable.updateStickyColumnStyles());
    }
  }
  @ViewChild("commonTableContainer", { static: true })
  commonTableContainer: ElementRef;
  @Input() columnHeaders: any[];
  @Input() tableData: any[];
  @Input() showFilterIcon = false;
  @Input() showeyeIcon = false;
  @Input() pagination = false;
  @Input() parentFunction = Function;
  @Input() isMobOrTab = false;
  @Input() showhover = false;
  @Input() showMoreIcon = false;
  @Input() moreOptions: any[];
  @Output() moreClickEvent = new EventEmitter();
  @Input() showEditIcon = false;
  @Input() showEditIconOnly = false;
  @Input() showDeleteIcon = false;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Input() linkFunction = Function;
  @Output() viewFunction = new EventEmitter();
  @Output() editFunction = new EventEmitter();
  @Output() copyFunction = new EventEmitter();
  @Output() copyToWgDataFunction = new EventEmitter();
  @Output() archiveFunction = new EventEmitter();
  @Output() checkboxHandler = new EventEmitter();
  @Output() deleteFunction = new EventEmitter();
  @Input() disabled = false;
  @Input() checked: any;
  @Input() showCheckbox = false;
  @Input() isEdit = true;
  @Input() prefernceName: string;
  @Input() defaultRadioSelectedIndex = 2;
  @Input() editOnly = false;
  // new UI revamp Changes----start
  @Input() showColumnLock = false;
  @Input() hideEyeIconFromHeader = false;
  @Input() userIsAdmin = false;
  @Input() userNotReadOnly = false;
  @Output() estimateListColumnPopover = new EventEmitter(); // ui redesign popover change
  @Output() compareEstimateEmitter = new EventEmitter();
  @Output() openEstimateDrawer = new EventEmitter();
  @Output() combineReportEmitter = new EventEmitter();
  @Output() repriceEmitter = new EventEmitter();
  // new UI revamp Changes----end
  private dataSubscribe: any;
  originalData: any[];
  prefernceData = {
    Name: "",
    Settings: [],
  };
  sortDirection = true;
  keys: string[];
  maxDate;
  minDate;
  langChangeLocal: any;
  byName = false;
  startUsage = false;
  endUsage = false;
  planNum = false;
  submit = false;
  startEndError = false;
  startInvalidError = false;
  startNullError = false;
  endInvalidError = false;
  endNullError = false;
  show = false;
  direction = "asc";
  displayColumns = [];
  @Input() showCompare = false;
  @Input() showReports = false;
  @Input() showReprice = false;
  @Input() estimatesCompare = [];
  @Input() includedReports = [];
  @Input() repriceElements = [];
  @Input() repriceDisab = false;
  page = 1;
  isMobile = false;
  totalLength: number;
  pageLimit = 25;
  pageStartIndex = 1;
  hoverData: any;
  rowSelected: any;
  isFilterPopUpIsOn = false;
  backupData: any[];
  workGroup: any = ["Caterpillar", "Joe Smith", "Toromont"];
  searchInput: string;
  popover: any;
  sourceName = AppConstant.sourceName;
  dataTable: any;
  viewEnabled = false;
  editEnabled = false;
  copyEnabled = false;
  copyToWgEnabled = false;
  shareEnabled = false;
  deleteEnabled = false;
  reportEnabled = false;
  archiveEnabled = false;
  currentRow: any;
  column = [];
  tableWidth: number;
  currentResizeIndex: any;
  startX: any;
  pressed: boolean;
  startWidth: any;
  start: HTMLTableHeaderCellElement;
  isResizingRight: boolean;
  resizableMousemove: () => void;
  resizableMouseup: () => void;
  columns = [];
  resizer: any;
  widthChange: number;
  displayedColumns: string[] = [];
  scrollHandleDisplayed = false;
  filterPopover: any;
  dataSource = new MatTableDataSource();
  pager: any;
  itemsPerPage: number;
  planNumber = 401;
  showMoredetails;
  lastprefernceData;
  checkboxChecked;
  showEditOption = true;
  filterToolTipLabel = this.translate.instant("dismiss");
  viewEstimate = this.translate.instant("view-tooltip");
  editEstimate = this.translate.instant("edit-tooltip");
  moreIcon = this.translate.instant("more");
  preview = this.translate.instant("estimatePreview");

  // new UI revamp Changes----start
  expandedElement = null;
  lockedColumnOrder = [];
  expndableRowColSpan = 0;
  expDetailWidth = 0;
  expDetailHeight = 200; // initial height setting of expanded details
  ExpandedDetailLeftAbsolutePos = 0;
  resizeLineMargin = 0;
  // new UI revamp Changes----end
  objJson = {
    byName: null,
    createdDate: null,
    lastUpdatedDate: null,
    slNoPrefix: null,
    status: null,
    startUsage: null,
    endUsage: null,
    createdBy: null,
    manufacturer: null,
    lastUpdatedBy: null,
    family: null,
    model: null,
    planNum: null,
  };
  defenitionType = DefinitionTypes.CCR;
  createdDateClone = null;
  updatedDateClone = null;
  updatedRepriceDateClone = null;
  status: any = ["All", "Draft", "Archived", "Finalized"];
  realTimeFilterChanged: Subject<any> = new Subject();
  PMValues = ["All", "True", "False"];
  PMSelectionIs = "";
  CCRSelectionIs = "";
  searchData: any[];
  statusFilter: any;
  pmOnlyFilter: any;
  CCRFilter: any;
  createdDateFilter: any;
  lastUpdatedDateFilter: any;
  lastRepricedDateFilter: any;
  calculatedMenuColumnWidth = 0;
  mousePos = null;
  isResizing = false;
  softDeletedDays = 60;
  isUserOrgIntermediate: boolean = false;
  wgUom: string;
  action: string;
  @Input() isMPEEstimate = false;
  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    public formatPipe: FormatCellPipe,
    private subjectService: SubjectService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private estimateService: EstimatePricingService,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    public modal: CcModal_,
    private modalRef: CcModal,
    private zone: NgZone,
    private dataService: DataService,
    private dateDifference: DateDifferencePipe,
    private mpeService: MultipleProductService
  ) {
    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
  }

  ngOnInit() {
    this.subscribeToUomChanges();
    this.subscribeToWgChanges();
    this.langChangeLocal = localStorage.getItem("locale");
    fromEvent(document.body, "mousemove").subscribe((e) => {
      this.mousePos = e;
    });
    this.getPrefernces();
    this.initListenChange();
    this.isMobileOrTablet();
    this.priceTypeCall();
    this.setDisplayedColumns();
    this.subjectService.matTableSearch$
      .pipe(debounceTime(500))
      .subscribe((searchTextValue) => {
        this.searchTable(searchTextValue.message);
      });
    this.backupData = { ...this.tableData };
    this.itemsPerPage = 100;
    if (this.showeyeIcon) {
      this.generateColumnHeader();
    } else {
      this.columnHeaders.forEach((col) => {
        this.displayColumns.push(col);
      });
    }
    if (this.pagination) {
      this.totalLength = this.tableData.length;
    }
    if (this.moreOptions) {
      this.generateMoreOptions();
    }

    this.expDetailWidth =
      this.commonTableContainer.nativeElement.clientWidth - 38;

    // to fix the width of expanded details with tableviewport
    this.realTimeFilterChanged
      .pipe(debounceTime(600))
      .subscribe((data: any) => {
        this.applyRealTimeSearch(data.columnName, data.searchTerm);
      });
  }
  applyRealTimeSearchFun(columnName: any, searchTerm: any) {
    const obj = {
      columnName,
      searchTerm,
    };
    this.realTimeFilterChanged.next(obj);
  }
  initListenChange() {
    this.subjectService.eventEmitListener$.subscribe((value) => {
      if (value && value.cancelTriggered) {
        if (value.reset) {
          this.columns.forEach((el) => {
            if (document.getElementById("id-" + el.label)) {
              const tempName = document.getElementById(
                "id-" + el.label
              ).firstElementChild;
              (tempName as HTMLInputElement).value = "";
            }
          });
          this.statusFilter = null;
          this.pmOnlyFilter = null;
          this.CCRFilter = null;
          this.lastUpdatedDateFilter = null;
          this.lastRepricedDateFilter = null;
          this.createdDateFilter = null;
        }
        this.closeFilter();
      }
    });
  }

  subscribeToUomChanges() {
    this.dataService.uomUpdated$.subscribe((updated) => {
      if (updated) {
        this.wgUom = this.dataService.getUom();
      }
    });
  }

  subscribeToWgChanges() {
    this.dataService.wgChanged$.subscribe((updated) => {
      if (updated) {
        this.wgUom = this.dataService.getUom();
      }
    });
  }

  priceTypeCall() {
    this.subjectService.priceTypeSelectionResult$.subscribe((data) => {
      this.setDisplayedColumns();
    });

    this.subjectService.eventEmitListener$.subscribe((data) => {
      if (data && data.refreshTable) {
        if (this.pagination) {
          this.setPaginatedData();
        } else {
          this.dataSource.data = this.tableData;
          this.totalLength = this.tableData.length;
        }
        this.setTableDisplay();
      }
      if (data && data.pagination) {
        this.pager = data.pagination;
        this.setPaginatedData();
      }
    });
  }

  ngOnChanges(changes: any) {
    if (changes && changes.tableData) {
      this.moreOptionsChange();
      if (!this.pagination) {
        this.dataSource.data = changes.tableData.currentValue;
      }
      this.tableData = changes.tableData.currentValue;
      if (changes.tableData.currentValue) {
        this.totalLength = changes.tableData.currentValue.length;
      }
      this.columnDisplay();
      this.originalData = _.clone(changes.tableData.currentValue);
      this.setTableDisplay();
      this.setPaginatedData();
      for (const i in this.columns) {
        if (this.columns[i].sortActive) {
          this.columns[i].order = !this.columns[i].order;
          this.sortTable(this.columns[i]);
          break;
        }
      }
    }
  }

  ngAfterContentChecked() {
    const arr = [];
    for (const key in this.columnHeaders) {
      if (this.columnHeaders[key].show) {
        arr.push({
          label: this.columnHeaders[key].value,
          width: this.columnHeaders[key].width,
          isLock: this.columnHeaders[key].isLock,
        });
      }
    }
    const arr1 = [];
    // tslint:disable-next-line: forin
    for (const i in arr) {
      if (arr[i]) {
        const value = JSON.stringify({
          width: arr[i].width,
          isLock: arr[i].isLock,
        });
        arr1.push({
          Name: arr[i].label,
          Value: value,
        });
      }
    }
    this.prefernceData.Name = this.prefernceName;
    this.prefernceData.Settings = arr1;
    this.calculateMenuColumnWidth();
  }

  postPrefernce() {
    this.updatePreferenceSettings();
    const sub = this.userService
      .saveUserPreferences(this.prefernceData)
      .subscribe(() => {
        const data = this.dataService.userPrefenceData;
        const userData = data.filter((elem) => elem.Name == this.prefernceName);
        if (userData.length != 0) {
          const index = this.dataService.userPrefenceData.indexOf(userData[0]);
          this.dataService.userPrefenceData.splice(index, 1);
        }
        this.dataService.userPrefenceData.push({
          Name: this.prefernceName,
          Settings: this.prefernceData.Settings,
        });
        sub.unsubscribe();
      });
  }

  getPrefernces() {
    if (this.prefernceName) {
      const data = this.dataService.userPrefenceData;
      const userData = data?.filter((elem) => elem.Name == this.prefernceName);
      if (userData?.length == 0) {
        this.settingPreferences(userData);
      } else {
        if (userData && userData.length > 0) {
          this.lastprefernceData = userData[0]?.Settings;
        }

        this.setPreferenceData();
        if (this.tableData && this.tableData.length > 0) {
          this.setDisplayedColumns();
        }
      }
    } else {
      this.setDisplayedColumns();
    }
  }

  setPreferenceData() {
    if (this.lastprefernceData && this.lastprefernceData.length > 0) {
      for (const key in this.columnHeaders) {
        if (key) {
          this.columnHeaders[key].show = false;
          this.settingPreferenceData(key);
        }
      }
    }
  }

  setPaginatedData() {
    if (this.pager && this.pagination) {
      const pagedData = this.tableData.slice(
        this.pager.startIndex,
        this.pager.endIndex + 1
      );
      this.itemsPerPage = this.pager.pageSize;
      this.dataSource.data = pagedData;
      this.totalLength = this.tableData.length;
      this.setTableDisplay();
    }
  }

  /* populating of table header columns, setting width for each column  -- start*/
  setDisplayedColumns() {
    this.displayedColumns = [];
    this.columns = [];
    if (this.showCheckbox) {
      this.displayedColumns.push("checkbox");
    }
    if (this.showhover) {
      this.displayedColumns.push("arrowBtn");
    }
    this.columnHeaders.forEach((column, index) => {
      if (column.show) {
        column.index = index;
        column.order = null;
        column.sortActive = false;
        this.columns.push(column);
        this.displayedColumns.push(column.value);
      }
    });
    if (this.showeyeIcon) {
      this.displayedColumns.push("eyeIcon");
    }
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }
  setTableResize(tableWidth: number) {
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const len = this.columns.length - 1;
    const scale = (tableWidth - len) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName("mat-column-" + column.value)
    );
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + "px";
    });
  }

  generateColumnHeader() {
    this.displayColumns = [];
    this.columnHeaders.forEach((col) => {
      if (col.show) {
        this.displayColumns.push(col);
      }
    });
  }

  /* populating of table header columns, setting width for each column  -- end*/

  /* resizing of column header --start*/

  onResizeColumn(event: any, index: number) {
    this.scrollHandleDisplayed = true;
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.columns[index].width;
    event.preventDefault();
    this.mouseMove(index, this.columns[index]);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  getCellData(index: number) {
    const headerRow = this.matTableRef.nativeElement.children[0];
    const row = headerRow.children[0];
    let cell = null;
    if (this.showCheckbox || this.showhover) {
      let count = 0;
      if (this.showCheckbox) {
        count++;
      }
      if (this.showhover) {
        count++;
      }
      cell = row.children[index + count];
    } else {
      cell = row.children[index];
    }
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number, column) {
    this.resizableMousemove = this.renderer.listen(
      "document",
      "mousemove",
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this.renderer.listen(
      "document",
      "mouseup",
      (event) => {
        this.postPrefernce();
        this.scrollHandleDisplayed = false;
        this.removeHandle(column.value);
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  addHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName("mat-column-" + id)[0];
      element.classList.add("border-right-style");
      if (element.getElementsByClassName("scroll-handle")[0]) {
        const scrollClass = element.getElementsByClassName("scroll-handle")[0];
        scrollClass.classList.add("display-class");
      }
    }
  }

  removeHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName("mat-column-" + id)[0];
      element.classList.remove("border-right-style");
      if (element.getElementsByClassName("scroll-handle")[0]) {
        const scrollClass = element.getElementsByClassName("scroll-handle")[0];
        scrollClass.classList.remove("display-class");
      }
    }
  }

  /* resizing of column header --end*/

  generateMoreOptions() {
    this.viewEnabled = false;
    this.editEnabled = false;
    this.copyEnabled = false;
    this.copyToWgEnabled = false;
    this.shareEnabled = false;
    this.deleteEnabled = false;
    this.reportEnabled = false;
    this.archiveEnabled = false;
    this.moreOptions.forEach((option) => {
      if (option.label === "View") {
        this.viewEnabled = true;
      } else if (option.label === "Edit") {
        this.editEnabled = true;
      } else if (option.label === "Copy") {
        this.copyEnabled = true;
      } else if (option.label === "CopyToWg") {
        this.copyToWgEnabled = true;
      } else if (option.label === "Share") {
        this.shareEnabled = true;
      } else if (option.label === "Delete") {
        this.deleteEnabled = true;
      } else if (option.label === "Report") {
        this.reportEnabled = true;
      } else if (option.label === "Archive") {
        this.archiveEnabled = true;
      }
    });
  }

  getStyleVal(elm, css) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }

  /* on changing hide/show options update the tableheader --start */

  togglePopover(flag: boolean, popover) {
    this.popover = popover;
    if (!flag) {
      if (this.checkboxChecked) {
        this.postPrefernce();
        this.checkboxChecked = false;
      }
      this.setDisplayedColumns();
      this.calculateMenuColumnWidth();
    }
    if (flag) {
      // if opening, the hide/show column need to update the enable/disable of columns (to handle locked column updation)
      this.headerChange();
    }
  }

  headerChange(event?, column?) {
    if (column) {
      this.checkboxChecked = true;
    }

    let count = 0;
    const tempCol = [];
    if (!event) {
      this.columnHeaders.forEach((col) => {
        if (col.show && col.value !== "eye") {
          tempCol.push(col);
          count++;
        }
      });
      if (count === 1) {
        tempCol.forEach((col) => {
          col.disabled = true;
        });
      }
    } else {
      this.columnHeaders.forEach((col) => {
        if (!col.alwaysDisable) {
          col.disabled = false;
        }
      });
    }
    if (this.lockedColumnOrder.length !== 0) {
      this.disableLockedColumns();
    }
    this.estimateListPopOver();
  }

  /* on changing hide/show options update the tableheader --end */

  getCssClass(row: any, key: any) {
    return this.parentFunction(row, key);
  }

  /* expanded table row functionalities --start */

  showExpandedRowDetails(row, index?, popover?, key?) {
    this.openEstimateDrawer.emit({ item: row });
  }
  expDetHeight(index) {
    const elmnt = document.getElementsByClassName(
      "expandedDetailSection"
    ) as HTMLCollectionOf<HTMLElement>;
    if (elmnt[index]) {
      this.expDetailHeight = elmnt[index].offsetHeight + 20;
    }
  }
  closePopover() {
    this.expandedElement = null;
  }
  onScrollOfDataTable() {
    // detect if table container is scrolled or not
    const elmnt = document.getElementById("common-table-container");
    this.ExpandedDetailLeftAbsolutePos = elmnt.scrollLeft; // sets the left absolute posittion expanded details
  }

  isRowExpanded(row) {
    if (this.expandedElement === row) {
      return true;
    } else {
      return false;
    }
  }

  countTotalColumns() {
    // will set the colspan value for expanded row details
    let count = this.columns.length;
    if (this.showhover) {
      count++;
    }
    if (this.showCheckbox) {
      count++;
    }
    if (this.showeyeIcon) {
      count++;
    }
    return count;
  }

  highlightRow(row) {
    if (row && row.Archived) {
      return true;
    }
    return false;
  }

  /* expanded table row functionalities --end */

  generateSerialNumber(estimate) {
    const arr = [];
    if (estimate.Range) {
      return estimate.Prefix + estimate.Range;
    }
  }

  recordShown(popover) {
    this.filterPopover = popover;
    this.isFilterPopUpIsOn = true;
  }

  recordHidden() {
    this.isFilterPopUpIsOn = false;
  }
  recordShownHover(popover) {
    if (popover.isOpen() && this.isFilterPopUpIsOn) {
      popover.close();
    }
  }

  dateConverter(date) {
    date = new Date(date);
    this.datePipe.transform(date, "MM-dd-yyyy");
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    const day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    const convertedDate = year + "/" + month + "/" + day;

    return convertedDate;
  }
  endDateConverter(date) {
    date = new Date(date);
    date.setDate(date.getDate() + 1);
    this.datePipe.transform(date, "MM-dd-yyyy");
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    const day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    const convertedDate = year + "/" + month + "/" + day;

    return convertedDate;
  }
  applyFilter(filterForm) {
    let value = "";
    const formObject = {
      formName: "Data Table Filter",
      formContent: null,
      formContent2: null,
      formStatus: "success",
    };
    const filters = {
      byName: (estimate, val) =>
        estimate &&
        estimate.PlanName &&
        estimate.PlanName.toLowerCase().includes(val),
      createdDate: (estimate, val) =>
        new Date(estimate && estimate.CreatedOn).getDate() >=
          new Date(this.dateConverter(val.begin)).getDate() &&
        new Date(estimate && estimate.CreatedOn).getDate() <=
          new Date(this.dateConverter(val.end)).getDate(),
      lastUpdatedDate: (estimate, val) =>
        new Date(estimate && estimate.LastModifiedOn).getDate() >=
          new Date(this.dateConverter(val.begin)).getDate() &&
        new Date(estimate && estimate.LastModifiedOn).getDate() <=
          new Date(this.dateConverter(val.end)).getDate(),
      lastRepricedDate: (estimate, val) =>
        new Date(estimate && estimate.FactorRevisionTime).getDate() >=
          new Date(this.dateConverter(val.begin)).getDate() &&
        new Date(estimate && estimate.FactorRevisionTime).getDate() <=
          new Date(this.dateConverter(val.end)).getDate(),
      slNoPrefix: (estimate, val) =>
        estimate &&
        estimate.Prefix &&
        estimate.Prefix.toLowerCase().includes(val),
      status: (estimate, val) =>
        estimate &&
        estimate.Status &&
        estimate.Status.toLowerCase().includes(val),
      startUsage: (estimate, val) => estimate && estimate.Start === Number(val),
      endUsage: (estimate, val) => estimate && estimate.End === Number(val),
      createdBy: (estimate, val) =>
        estimate &&
        estimate.CreatedBy &&
        estimate.CreatedBy.toLowerCase().includes(val),
      manufacturer: (estimate, val) =>
        estimate &&
        estimate.ManufacturerName &&
        estimate.ManufacturerName.toLowerCase().includes(val),
      lastUpdatedBy: (estimate, val) =>
        estimate &&
        estimate.ModifiedBy &&
        estimate.ModifiedBy.toLowerCase().includes(val),
      family: (estimate, val) =>
        estimate &&
        estimate.FamilyName &&
        estimate.FamilyName.toLowerCase().includes(val),
      model: (estimate, val) =>
        estimate &&
        estimate.Model &&
        estimate.Model.toLowerCase().includes(val),
      planNum: (estimate, val) =>
        estimate && estimate.PlanNumber === Number(val),
      estimateLength: (estimate, val) =>
        estimate && estimate.End - estimate.Start === Number(val),
    };

    // tslint:disable-next-line: max-line-length
    Object.keys(filterForm.value).forEach((key, index, array) => {
      if (
        filterForm.value[key] === "" ||
        filterForm.value[key] === undefined ||
        filterForm.value[key] === null
      ) {
        delete filterForm.value[key];
      } else {
        formObject.formContent = filterForm.value[key];
        const temp =
          index < array.length - 1
            ? "User Entered Value |"
            : "User Entered Value";
        value = value + temp;
      }
    });

    filterForm.value = Object.keys(filterForm.value).reduce((n, k) => {
      n[k] =
        typeof filterForm.value[k] == "string"
          ? filterForm.value[k].toLowerCase()
          : filterForm.value[k];
      return n;
    }, {});
    const checkIfItemHasObject = (item, val) => {
      return (
        Object.keys(val).filter((key) => filters[key](item, val[key]))
          .length === Object.keys(val).length
      );
    };
    formObject.formContent2 = value;
    this.subjectService.setFormChangesStream({
      isFormSubmitted: true,
      formValue: formObject,
    });
    const res = this.originalData.filter((estimate) =>
      checkIfItemHasObject(estimate, filterForm.value)
    );
    this.tableData = res;
    this.dataSource.data = this.tableData;
    this.totalLength = res.length;
    this.setTableDisplay();
  }
  closeFilter() {
    this.objJson = {
      byName: null,
      createdDate: null,
      lastUpdatedDate: null,
      slNoPrefix: null,
      status: null,
      startUsage: null,
      endUsage: null,
      createdBy: null,
      manufacturer: null,
      lastUpdatedBy: null,
      family: null,
      model: null,
      planNum: null,
    };
    this.updatedDateClone = null;
    this.updatedRepriceDateClone = null;
    this.createdDateClone = null;
    this.searchData = [];
    this.statusFilter = null;
    this.pmOnlyFilter = null;
    this.CCRFilter = null;
    this.lastUpdatedDateFilter = null;
    this.lastRepricedDateFilter = null;
    this.createdDateFilter = null;
  }

  onStartUsageBlur() {
    this.startUsage = true;
  }

  onEndUsageBlur() {
    this.endUsage = true;
  }

  isMobileOrTablet() {
    // tslint:disable-next-line: max-line-length
    if (
      appConstants.mobOrTabCheckReg1.test(navigator.userAgent) ||
      // tslint:disable-next-line: max-line-length
      appConstants.mobOrTabCheckReg2.test(navigator.userAgent.substr(0, 4))
    ) {
      this.isMobOrTab = true;
    }
  }

  moreClick(target) {
    const dataObj = {
      target,
      data: this.rowSelected,
    };
    this.moreClickEvent.emit(dataObj);
  }

  assignSelectedRow(row) {
    this.currentRow = row;
    this.showMoredetails = row.Archived;
    if (row && row.Status === "Finalized") {
      this.showEditOption = false;
    } else {
      this.showEditOption = true;
    }
  }

  viewData(row) {
    this.assignSelectedRow(row);
    this.viewFunction.emit(this.currentRow);
  }

  editData(row, templateRef) {
    this.action = "edit";
    this.checkWgUom(row, templateRef);
  }

  checkWgUom(row, templateRef) {
    if (this.wgUom !== row.SystemOfMeasurement) {
      this.rowData = row;
      this.openModalWithTemplate(templateRef);
    } else {
      this.assignSelectedRow(row);
      this.editFunction.emit(this.currentRow);
    }
  }

  showSnackBarMsg(msg) {
    this.snackBar.open(msg, "", {
      duration: 4000,
      panelClass: ["status-snack-bar"],
    });
  }

  unsubscribeAndServerError(modal) {
    modal.close();
    this.showSnackBarMsg(this.translate.instant("serverErr"));
  }

  checkEstimateStatus(modal) {
    this.mpeService.pingEstimateStatus(this.rowData.PlanNumber).subscribe(
      (res) => {
        if (res.IsSuccess) {
          if (res.OverallStatus == "Completed") {
            modal.close();
            this.assignSelectedRow(this.rowData);
            this.editFunction.emit(this.currentRow);
          } else if (res.OverallStatus == "Failed") {
            modal.close();
            this.showSnackBarMsg(this.translate.instant("unknownerr"));
          }
        } else {
          modal.close();
          this.showSnackBarMsg(this.translate.instant("unknownerr"));
        }
      },
      (err) => {
        if (err.status === 0 || err.status === 504) {
          modal.close();
          this.showSnackBarMsg(this.translate.instant("networkError"));
        } else {
          this.unsubscribeAndServerError(modal);
        }
      }
    );
  }

  onProceed() {
    if (this.action === "edit") {
      this.callEstimateAndApply(this.rowData.PlanNumber);
    }
    if (this.action === "copy") {
      this.modalRef.close();
      this.copyFunction.emit(this.currentRow);
    }
  }

  callEstimateAndApply(planNumber) {
    this.modalRef.close();
    const modal = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "progress-spinner-modal",
      keyboard: false,
    });
    modal.componentInstance.message = this.translate.instant("waitMsg");
    const estUomUpdatePayload = [
      {
        SystemOfMeasurement: this.wgUom,
      },
    ];
    if (!this.isMPEEstimate) {
      this.estimateService
        .editEstimateAndApply(planNumber, estUomUpdatePayload)
        .subscribe(
          (response) => {
            if (
              response &&
              response.CheckoutResult &&
              response.CheckoutResult.Success
            ) {
              if (response.OverallStatus === "Success") {
                modal.close();
                this.assignSelectedRow(this.rowData);
                this.editFunction.emit(this.currentRow);
              } else if (response.OverallStatus === "Failure") {
                modal.close();
                this.showSnackBarMsg(this.translate.instant("unknownerr"));
              }
            }
          },
          (err) => {
            modal.close();
            if (err.status === 0 || err.status == 504) {
              this.showSnackBarMsg(this.translate.instant("networkError"));
            } else {
              this.showSnackBarMsg(this.translate.instant("serverErr"));
            }
          }
        );
    } else {
      this.mpeService
        .editEstimateAndApply(planNumber, estUomUpdatePayload)
        .subscribe(
          (response) => {
            if (response && response.IsSuccess) {
              this.checkEstimateStatus(modal);
            } else {
              modal.close();
              this.showSnackBarMsg(this.translate.instant("unknownerr"));
            }
          },
          (err) => {
            modal.close();
            if (err.status === 0 || err.status == 504) {
              this.showSnackBarMsg(this.translate.instant("networkError"));
            } else {
              this.showSnackBarMsg(this.translate.instant("serverErr"));
            }
          }
        );
    }
  }

  openModalWithTemplate(content: any) {
    this.modalRef.openModal(content, {
      type: "semi-modal",
      width: "537px",
      data: { name: "test" },
      panelClass: "atom-pg-panelClass",
      backdropClass: "overlay-for-filter",
      isAutoHeightModalContent: true,
      hasScroll: true,
      closeOnEsc: false,
      disableBackdropClick: true,
    });
  }

  copyData(templateRef) {
    this.action = "copy";
    if (this.wgUom !== this.currentRow.SystemOfMeasurement) {
      this.openModalWithTemplate(templateRef);
    } else {
      this.copyFunction.emit(this.currentRow);
    }
  }

  copyToWgData(templateRef) {
    this.copyToWgDataFunction.emit(this.currentRow);
  }

  deleteData() {
    this.deleteFunction.emit(this.currentRow);
  }

  edit(target, j) {
    const dataObj = {
      j,
      row: target,
    };
    this.editEvent.emit(dataObj);
  }
  delete(target, j) {
    const dataObj = {
      j,
      row: target,
    };
    this.deleteEvent.emit(dataObj);
  }

  // reportgenerate
  showGenerateReportModal() {
    if (
      this.currentRow.FactorRevisionStatus === "WaitingForAdditionalFactors"
    ) {
      this.showErrMsg();
    } else {
      const duration = this.currentRow.End - this.currentRow.Start;
      const modalRef = this.modal.openModal(ViewReportOptionsComponent, {
        backdropClass: "cc-modal-full-window-backdrop",
        panelClass: "cc-modal-full-window",
        hasScroll: true,
        data: {
          planNumber: this.currentRow.PlanNumber,
          estimateName: this.currentRow.PlanName,
          duration,
        },
      });
      modalRef.afterClosed().subscribe();
    }
  }
  archiveData() {
    this.archiveFunction.emit(this.currentRow);
  }
  onEstimateNameBlur() {
    this.byName = true;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.expDetailWidth =
      this.commonTableContainer.nativeElement.clientWidth - 38;
    this.closePopover();
    this.calculateMenuColumnWidth();
  }

  checkTrueOrFalse(element, column) {
    if (
      column.label == "isCCR" &&
      element[column.keyPath] == "CATCertifiedRebuild"
    ) {
      return true;
    }
    if (
      column.label == "includePM" &&
      this.formatPipe.transform(element[column.keyPath], column, element)
    ) {
      return true;
    }

    return false;
  }

  checkboxValueChanged(element) {
    this.checkboxHandler.emit(element);
  }

  onSearch(searchTextValue: string, event: Event) {
    event.stopPropagation();
    this.subjectService.sendMessage(searchTextValue, "matTableSearch");
  }

  searchTable(searchTerm) {
    if (searchTerm) {
      let results;
      results = this.searchTableDetails(searchTerm);
      this.tableData = results;
      this.totalLength = results.length;
      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    } else {
      if (this.commonService.adv && this.isJsonEmpty(this.objJson)) {
        if (this.searchData && this.searchData.length > 0) {
          this.tableData = this.searchData;
        } else {
          this.tableData = null;
        }
      } else {
        this.tableData = this.originalData;
      }
    }
    this.searchTableData();
  }

  searchTableData() {
    if (this.pagination) {
      this.setPaginatedData();
    } else {
      this.totalLength = this.tableData.length;
      this.dataSource.data = this.tableData;
      this.setTableDisplay();
    }
  }
  searchTableDetails(searchTerm) {
    let results;
    if (this.commonService.adv && this.isJsonEmpty(this.objJson)) {
      results = this.commonService.matTableSearch(
        searchTerm,
        this.searchData,
        this.columnHeaders
      );
    } else {
      results = this.commonService.matTableSearch(
        searchTerm,
        this.originalData,
        this.columnHeaders
      );
    }
    return results;
  }
  /* sorting of data table --start */

  sortTable(column) {
    this.closePopover(); // to close the expanded details on click of sort

    this.resetSort();
    column.order = !column.order;
    column.sortActive = true;
    const unordered = [];
    const orderedArr = [];
    this.tableData.forEach((row, index) => {
      const cellData = this.formatPipe.transform(undefined, column, row);
      let rawData;
      if (column.hasOwnProperty("currency")) {
        rawData = this.formatPipe.getAmount(column, row);
      } else {
        rawData = cellData;
      }
      const data = {
        cellData,
        row,
        rawData,
      };
      unordered.push(data);
    });

    // column.order = true means ascending
    // column.order = false means descending
    if (column.order) {
      unordered.sort(this.toAscending);
    } else {
      unordered.sort(this.toDescending);
    }

    unordered.forEach((item) => {
      orderedArr.push(item.row);
    });
    this.tableData = orderedArr;
    if (this.pagination) {
      this.setPaginatedData();
    } else {
      this.dataSource.data = this.tableData;
    }
  }

  toAscending(a, b) {
    if (a.cellData || a.cellData === 0 || a.cellData === false) {
      // Use toUpperCase() to ignore character casing
      const x =
        typeof a.rawData === "string" ? a.rawData.toUpperCase() : a.rawData;
      const y =
        typeof b.rawData === "string" ? b.rawData.toUpperCase() : b.rawData;
      let comparison = 0;
      if (x > y) {
        comparison = 1;
      } else if (x < y) {
        comparison = -1;
      }
      return comparison;
    }
  }

  toDescending(a, b) {
    // Use toUpperCase() to ignore character casing
    if (a.cellData || a.cellData === 0 || a.cellData === false) {
      const x =
        typeof a.rawData === "string" ? a.rawData.toUpperCase() : a.rawData;
      const y =
        typeof b.rawData === "string" ? b.rawData.toUpperCase() : b.rawData;
      let comparison = 0;
      if (x > y) {
        comparison = 1;
      } else if (x < y) {
        comparison = -1;
      }
      return comparison * -1;
    }
  }

  resetSort() {
    this.columns.forEach((item) => {
      item.sortActive = false;
    });
    this.setTableDisplay();
    this.setPaginatedData();
  }

  /* sorting of data table --end */

  setTableDisplay() {
    if (this.totalLength === 0) {
      const element = document.getElementById("common-data-table-v2");
      if (element) {
        element.style.display = "none";
      }
      const tableElement = document.getElementById("common-table-container");
      if (tableElement) {
        tableElement.classList.add("no-border");
      }
    } else {
      const element = document.getElementById("common-data-table-v2");
      if (element) {
        element.style.display = "table";
      }
      const tableElement = document.getElementById("common-table-container");
      if (tableElement) {
        tableElement.classList.remove("no-border");
      }
    }
  }

  estimateListPopOver() {
    // the trigger is given to estimate component to update the eye popover content
    this.estimateListColumnPopover.emit(this.columnHeaders);
  }

  /* lock functionality --start */

  lockColumn(columnValue?, clickedLock?) {
    const sortedColumnHeader = [];
    if (columnValue) {
      if (this.lockedColumnOrder.indexOf(columnValue) > -1) {
        // 'lockedColumnOrder' contain columns that is locked.
        // while unlocking the column is removed from the locked column list using splice
        this.lockedColumnOrder.splice(
          this.lockedColumnOrder.indexOf(columnValue),
          1
        );
      } else {
        // if trying to lock , will push into the locked column list.
        this.lockedColumnOrder.push(columnValue);
      }
    }

    this.lockedColumnOrder.forEach((lockedColumn) => {
      // to fetch the locked column details and storein 'sortedColumnHeader'
      this.columnHeaders.forEach((column) => {
        if (lockedColumn === column.value) {
          sortedColumnHeader.push(column);
        }
      });
    });

    this.columnHeaders.forEach((column) => {
      // initial columns in the array  should be 'locked columns'
      if (this.lockedColumnOrder.indexOf(column.value) > -1 === false) {
        sortedColumnHeader.push(column);
      }
    });
    this.columnHeaders = sortedColumnHeader;
    this.setDisplayedColumns();
    if (columnValue) {
      /** calling fn to disable the column that is locked */
      this.headerChangeAfterLock(columnValue, clickedLock);
    }
  }

  isStickyColumn(columnValue) {
    // determine whether sticky prop should be applied or not
    return this.checkForColumnIndex(columnValue);
  }

  isLockOn(columnValue) {
    // change color of lock based on whether the column is locked or not
    return this.checkForColumnIndex(columnValue);
  }

  checkForColumnIndex(columnValue) {
    if (this.lockedColumnOrder.indexOf(columnValue) > -1) {
      return true;
    } else {
      return false;
    }
  }

  isShowLock(columnValue) {
    if (this.lockedColumnOrder.length < 2) {
      return true;
    } else {
      if (this.isLockOn(columnValue)) {
        return true;
      } else {
        return false;
      }
    }
  }

  disableLockedColumns() {
    this.lockedColumnOrder.forEach((lockedColumn) => {
      // to fetch the locked column details and storein 'sortedColumnHeader'
      this.columnHeaders.forEach((column) => {
        if (lockedColumn === column.value) {
          column.disabled = true;
        }
      });
    });
  }

  headerChangeAfterLock(columnValue, clickedLock?) {
    /** to disable the column that is locked */

    let count = 0;
    this.columnHeaders.forEach((col) => {
      if (col.show && col.value !== "eye") {
        count++;
      }
    });
    this.columnHeaders.forEach((column) => {
      if (columnValue === column.value) {
        if (this.lockedColumnOrder.indexOf(columnValue) > -1) {
          column.disabled = true;
        } else {
          if (count > 1) {
            column.disabled = false;
          }
        }
      }
    });
    this.estimateListPopOver();
    if (clickedLock) {
      this.postPrefernce();
    }
  }
  updatePreferenceSettings() {
    for (const key in this.prefernceData.Settings) {
      if (
        this.lockedColumnOrder.indexOf(this.prefernceData.Settings[key].Name) >
        -1
      ) {
        const value = JSON.stringify({
          width: JSON.parse(this.prefernceData.Settings[key].Value).width,
          isLock: true,
        });
        this.prefernceData.Settings[key].Value = value;
      } else {
        const value = JSON.stringify({
          width: JSON.parse(this.prefernceData.Settings[key].Value).width,
          isLock: false,
        });
        this.prefernceData.Settings[key].Value = value;
      }
    }
  }

  /* lock functionality --end */

  ngOnDestroy() {
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
    }
  }
  /* Real-time search functionality begins */

  checkIfItemHasByName = (estimate: any, val: any) =>
    estimate &&
    estimate.PlanName &&
    estimate.PlanName.toLowerCase().includes(val);

  checkIfItemHasCreatedOn = (estimate: any, val: any) =>
    new Date(
      estimate &&
        estimate.CreatedOn.substr(
          0,
          estimate && estimate.CreatedOn.indexOf("T")
        )
    ).getTime() >= new Date(this.dateConverter(val.begin)).getTime() &&
    new Date(
      estimate &&
        estimate.CreatedOn.substr(
          0,
          estimate && estimate.CreatedOn.indexOf("T")
        )
    ).getTime() <= new Date(this.endDateConverter(val.end)).getTime();

  checkIfItemHasLastModifiedOn = (estimate: any, val: any) =>
    new Date(
      estimate &&
        estimate.LastModifiedOn.substr(
          0,
          estimate && estimate.LastModifiedOn.indexOf("T")
        )
    ).getTime() >= new Date(this.dateConverter(val.begin)).getTime() &&
    new Date(
      estimate &&
        estimate.LastModifiedOn.substr(
          0,
          estimate && estimate.LastModifiedOn.indexOf("T")
        )
    ).getTime() <= new Date(this.endDateConverter(val.end)).getTime();

  checkIfItemHasLastRepricedOn = (estimate: any, val: any) =>
    new Date(
      estimate &&
        estimate.FactorRevisionTime &&
        estimate.FactorRevisionTime.substr(
          0,
          estimate && estimate.FactorRevisionTime.indexOf("T")
        )
    ).getTime() >= new Date(this.dateConverter(val.begin)).getTime() &&
    new Date(
      estimate &&
        estimate.FactorRevisionTime &&
        estimate.FactorRevisionTime.substr(
          0,
          estimate && estimate.FactorRevisionTime.indexOf("T")
        )
    ).getTime() <= new Date(this.endDateConverter(val.end)).getTime();

  checkIfItemHasPrefix = (estimate: any, val: any) =>
    estimate && estimate.Prefix && estimate.Prefix.toLowerCase().includes(val);

  checkIfItemHasStatus = (estimate: any, val: any) =>
    estimate && estimate.Status && estimate.Status.toLowerCase().includes(val);

  checkIfItemHasStart = (estimate: any, val: any) =>
    estimate && estimate.Start.toString().indexOf(val.toString()) > -1;

  checkIfItemHasEnd = (estimate: any, val: any) =>
    estimate && estimate.End.toString().indexOf(val.toString()) > -1;

  checkIfItemHasCreatedBy = (estimate: any, val: any) =>
    estimate &&
    estimate.CreatedBy &&
    estimate.CreatedBy.toLowerCase().includes(val);

  checkIfItemHasManufacturerName = (estimate: any, val: any) =>
    estimate &&
    estimate.ManufacturerName &&
    estimate.ManufacturerName.toLowerCase().includes(val);

  checkIfItemHasLastModifiedBy = (estimate: any, val: any) =>
    estimate &&
    estimate.LastModifiedBy &&
    estimate.LastModifiedBy.toLowerCase().includes(val);

  checkIfItemHasFamilyName = (estimate: any, val: any) =>
    estimate &&
    estimate.FamilyName &&
    estimate.FamilyName.toLowerCase().includes(val);

  checkIfItemHasModel = (estimate: any, val: any) =>
    estimate && estimate.Model && estimate.Model.toLowerCase().includes(val);

  checkIfItemHasIncludePM = (estimate: any, val: any) =>
    estimate && estimate.IncludePM === val;

  checkIfItemHasIsCCR = (estimate: any, val: any) =>
    estimate && (estimate.DefinitionType == "CATCertifiedRebuild") === val;

  checkIfItemHasRange = (estimate: any, val: any) =>
    estimate && estimate.Range && estimate.Range.includes(val);

  checkIfItemHasCustomerName = (estimate: any, val: any) =>
    estimate &&
    estimate.CustomerName &&
    estimate.CustomerName.toLowerCase().includes(val);

  checkIfItemHasArchived = (estimate: any, val: any) =>
    estimate && estimate.Archived === val;

  checkIfItemHasPlanNumber = (estimate: any, val: any) =>
    estimate && estimate.PlanNumber.toString().indexOf(val.toString()) > -1;

  checkIfItemHasEstimateLength = (estimate: any, val: any) =>
    estimate && estimate.End - estimate.Start == val;

  applyRealTimeSearch(columnName: any, searchTerm: any) {
    const archieveKey = "includeArchieve";
    const controlName = this.getColumnName(columnName);
    if (
      columnName === "lastUpdatedDate" ||
      columnName === "createdDate" ||
      columnName === "end" ||
      columnName === "start" ||
      columnName === "includePM" ||
      columnName === "isCCR" ||
      columnName === "lastRepricedDate"
    ) {
      this.objJson[controlName] = searchTerm;
    } else if (columnName === "status" && searchTerm === "Archived") {
      this.objJson[archieveKey] = true;
    } else {
      this.objJson[controlName] = searchTerm.toLowerCase();
    }
    const filters1 = {
      byName: this.checkIfItemHasByName,
      createdDate: this.checkIfItemHasCreatedOn,
      lastUpdatedDate: this.checkIfItemHasLastModifiedOn,
      lastRepricedDate: this.checkIfItemHasLastRepricedOn,
      slNoPrefix: this.checkIfItemHasPrefix,
      status: this.checkIfItemHasStatus,
      startUsage: this.checkIfItemHasStart,
      endUsage: this.checkIfItemHasEnd,
      createdBy: this.checkIfItemHasCreatedBy,
      manufacturer: this.checkIfItemHasManufacturerName,
      lastUpdatedBy: this.checkIfItemHasLastModifiedBy,
      family: this.checkIfItemHasFamilyName,
      model: this.checkIfItemHasModel,
      includePM: this.checkIfItemHasIncludePM,
      isCCR: this.checkIfItemHasIsCCR,
      serialNoRange: this.checkIfItemHasRange,
      customer: this.checkIfItemHasCustomerName,
      includeArchieve: this.checkIfItemHasArchived,
      planNum: this.checkIfItemHasPlanNumber,
      estimateLength: this.checkIfItemHasEstimateLength,
    };

    Object.keys(this.objJson).forEach(
      (k) =>
        !this.objJson[k] &&
        this.objJson[k] !== undefined &&
        delete this.objJson[k]
    );

    const checkIfItemHasObject = (item, val) => {
      return (
        Object.keys(val).filter((key) => filters1[key](item, val[key]))
          .length === Object.keys(val).length
      );
    };

    if (
      columnName === "includePM" &&
      !searchTerm &&
      this.PMSelectionIs !== "all"
    ) {
      // ---> this if cdtn is for includepm filter issue when false...
      this.objJson[controlName] = searchTerm;
    }

    if (
      columnName === "isCCR" &&
      !searchTerm &&
      this.CCRSelectionIs !== "all"
    ) {
      // ---> this if cdtn is for includeccr filter issue when false...
      this.objJson[controlName] = searchTerm;
    }

    const res = this.originalData.filter((estimate) =>
      checkIfItemHasObject(estimate, this.objJson)
    );
    if (this.objJson[archieveKey] === true) {
      delete this.objJson[archieveKey];
    }
    this.objJson.status = "";
    this.searchData = res;
    this.tableData = res;
    this.dataSource.data = this.tableData;
    this.totalLength = res.length;
    this.setTableDisplay();
  }
  getColumnName(columnName) {
    const cName = {
      serialPrefix: "slNoPrefix",
      Manufacturer: "manufacturer",
      start: "startUsage",
      name: "byName",
      model: "model",
      end: "endUsage",
      family: "family",
      status: "status",
      customer: "customer",
      createdBy: "createdBy",
      createdDate: "createdDate",
      serialRange: "serialNoRange",
      lastUpdatedBy: "lastUpdatedBy",
      lastUpdatedDate: "lastUpdatedDate",
      lastRepricedDate: "lastRepricedDate",
      includePM: "includePM",
      isCCR: "isCCR",
      estimateNumber: "planNum",
      estimateLength: "estimateLength",
      default: columnName,
    };
    return cName[columnName];
  }
  setUpdatedDate(event) {
    if (event.value) {
      this.updatedDateClone = event.value;
    } else {
      this.updatedDateClone = null;
    }
    const obj = {
      columnName: "lastUpdatedDate",
      searchTerm: this.updatedDateClone,
    };
    this.realTimeFilterChanged.next(obj);
  }

  setUpdatedRepriceDate(event) {
    if (event.value) {
      this.updatedRepriceDateClone = event.value;
    } else {
      this.updatedRepriceDateClone = null;
    }
    const obj = {
      columnName: "lastRepricedDate",
      searchTerm: this.updatedRepriceDateClone,
    };
    this.realTimeFilterChanged.next(obj);
  }

  setCreatedDate(event) {
    if (event.value) {
      this.createdDateClone = event.value;
    } else {
      this.createdDateClone = null;
    }
    const obj = {
      columnName: "createdDate",
      searchTerm: this.createdDateClone,
    };
    this.realTimeFilterChanged.next(obj);
  }

  setStatus(statusValue) {
    if (statusValue === "All") {
      statusValue = "";
    }
    const obj = {
      columnName: "status",
      searchTerm: statusValue,
    };
    this.realTimeFilterChanged.next(obj);
  }
  setPMs(selectedValue) {
    if (selectedValue === "True") {
      this.PMSelectionIs = "true";
      selectedValue = true;
    } else if (selectedValue === "False") {
      selectedValue = false;
      this.PMSelectionIs = "false";
    } else if (selectedValue === "All") {
      this.PMSelectionIs = "all";
      selectedValue = "";
    }
    const obj = {
      columnName: "includePM",
      searchTerm: selectedValue,
    };
    this.realTimeFilterChanged.next(obj);
  }

  setCCR(selectedValue) {
    if (selectedValue === "True") {
      this.CCRSelectionIs = "true";
      selectedValue = true;
    } else if (selectedValue === "False") {
      selectedValue = false;
      this.CCRSelectionIs = "false";
    } else if (selectedValue === "All") {
      this.CCRSelectionIs = "all";
      selectedValue = "";
    }
    const obj = {
      columnName: "isCCR",
      searchTerm: selectedValue,
    };
    this.realTimeFilterChanged.next(obj);
  }
  isJsonEmpty(obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== "") {
        return true;
      }
    }
    return false;
  }

  /* Real-time search functionality ends */

  showButton(ellipseBtnRow, ellipseBtn1, ellipseBtn2) {
    const dataTableContainerHeight = document
      .getElementById("common-table-container")
      .getBoundingClientRect().height;
    const divContainerTop = document.getElementById(
      "common-table-container"
    ).offsetTop;
    const elementTop = ellipseBtnRow.offsetTop;
    const elementRelativeTop =
      elementTop -
      document.getElementById("common-table-container").scrollTop -
      divContainerTop;

    if (elementRelativeTop < dataTableContainerHeight / 2) {
      document.getElementById(ellipseBtn1).click();
    } else {
      document.getElementById(ellipseBtn2).click();
    }
  }

  resetfilter() {
    this.subjectService.sendMessage("", "closeFilterListener");
  }

  getWG() {
    const wg = this.dataService.defaultOrgWG.WorkGroupName;
    return (
      wg.charAt(0).toUpperCase() +
      this.dataService.defaultOrgWG.WorkGroupName.slice(1).toLowerCase()
    );
  }

  onResizeEnd(event: ResizeEvent, columnName, index): void {
    this.resizeLineMargin = 0;
    this.isResizing = false;
    if (event.edges.right) {
      const cssValue = event.rectangle.width + "px";
      const columnElts = document.getElementsByClassName(
        "mat-column-" + columnName
      );
      for (let i = 0; i < columnElts.length; i++) {
        const currentEl = columnElts[i] as HTMLDivElement;
        currentEl.style.width = cssValue;
      }
      this.columns[index].width = columnElts[0].clientWidth;
      for (const key in this.prefernceData.Settings) {
        this.changePreferenceValue(key, columnName, columnElts);
      }
      this.calculateMenuColumnWidth();
      this.postPrefernce();
    }
  }

  changePreferenceValue(key, columnName, columnElts) {
    if (
      this.lockedColumnOrder.indexOf(this.prefernceData.Settings[key].Name) > -1
    ) {
      if (this.prefernceData.Settings[key].Name === columnName) {
        const value = JSON.stringify({
          width: columnElts[0].clientWidth,
          isLock: true,
        });
        this.prefernceData.Settings[key].Value = value;
      }
    } else {
      if (this.prefernceData.Settings[key].Name === columnName) {
        const value = JSON.stringify({
          width: columnElts[0].clientWidth,
          isLock: false,
        });
        this.prefernceData.Settings[key].Value = value;
      }
    }
  }

  calculateMenuColumnWidth() {
    let totalColumnsWidth = 0;
    for (const key in this.columns) {
      const el = document.getElementsByClassName(
        "mat-column-" + this.columns[key].value
      );
      if (el.length) {
        totalColumnsWidth = totalColumnsWidth + el[0].clientWidth;
      }
    }
    const dataTableContainerWidth = document
      .getElementById("common-table-container")
      .getBoundingClientRect().width;

    if (dataTableContainerWidth > totalColumnsWidth + 50) {
      this.calculatedMenuColumnWidth =
        dataTableContainerWidth - (totalColumnsWidth + 70);
    } else {
      this.calculatedMenuColumnWidth = 20;
    }
  }

  onResizing(resizeEvent: ResizeEvent) {
    if (
      resizeEvent.rectangle.width > 150 &&
      resizeEvent.rectangle.right <
        document
          .getElementById("common-table-container")
          .getBoundingClientRect().width
    ) {
      this.resizeLineMargin = this.mousePos.clientX;
    }
    this.isResizing = true;
  }

  checkboxCompare(data, event) {
    if (event.target.checked) {
      const index = this.estimatesCompare.indexOf(data);
      if (index != -1) {
        this.estimatesCompare.splice(index, 1);
      }
      this.estimatesCompare.push(data);
    } else {
      const index = this.estimatesCompare.indexOf(data);
      if (index != -1) {
        this.estimatesCompare.splice(index, 1);
      }
    }
    this.compareEstimateEmitter.emit(this.estimatesCompare);
  }
  includeReportsFunction(data, event) {
    if (event.target.checked) {
      const index = this.includedReports.indexOf(data);
      if (index != -1) {
        this.includedReports.splice(index, 1);
      }
      this.includedReports.push(data);
    } else {
      const index = this.includedReports.indexOf(data);
      if (index != -1) {
        this.includedReports.splice(index, 1);
      }
    }
    this.combineReportEmitter.emit(this.includedReports);
  }

  checkCondition(timeToDel) {
    if (timeToDel) {
      if (this.dateDifference.transform(timeToDel) < this.softDeletedDays) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  moreOptionsChange() {
    if (this.moreOptions) {
      this.generateMoreOptions();
    }
  }
  columnDisplay() {
    if (
      this.tableData &&
      this.tableData.length > 0 &&
      this.columns.length === 0
    ) {
      this.setDisplayedColumns();
    }
  }
  settingPreferences(userData) {
    this.dataSubscribe = this.userService
      .getUserPreferences(this.prefernceName)
      .subscribe(
        (response) => {
          this.lastprefernceData = response.Settings;
          this.dataService.userPrefenceData.push({
            Name: this.prefernceName,
            Settings: this.lastprefernceData,
          });
          this.setPreferenceData();
          if (this.tableData && this.tableData.length > 0) {
            this.setDisplayedColumns();
          }
        },
        (err) => {
          if (this.tableData && this.tableData.length > 0) {
            this.setDisplayedColumns();
          }
        }
      );
  }
  settingPreferenceData(key) {
    for (const keys in this.lastprefernceData) {
      if (this.lastprefernceData[keys].Name === this.columnHeaders[key].value) {
        this.columnHeaders[key].show = true;
        const valueObj = JSON.parse(this.lastprefernceData[keys].Value);
        this.columnHeaders[key].width = Number(valueObj.width);
        if (valueObj.isLock) {
          // Is column locked
          this.lockColumn(this.columnHeaders[key].value);
        }
        if (this.lastprefernceData.length === 1) {
          this.columnHeaders[key].disabled = true;
        }
      }
    }
  }

  repriceFunction(data, event) {
    if (event.target.checked) {
      const index = this.repriceElements.indexOf(data);
      if (index != -1) {
        this.repriceElements.splice(index, 1);
      }
      this.repriceElements.push(data);
    } else {
      const index = this.repriceElements.indexOf(data);
      if (index != -1) {
        this.repriceElements.splice(index, 1);
      }
    }
    this.repriceEmitter.emit(this.repriceElements);
  }
  showErrMsg() {
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdropClass: "restore-backdrop",
      backdrop: "static",
    });
    modalRef.componentInstance.alertMessage =
      this.translate.instant("errorReportgenMsg") +
      "\n" +
      this.translate.instant("try_again");
    modalRef.componentInstance.estimateRelease = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.error = true;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary btn-width btn-mr"],
        handler: (modal) => {
          modal.close(false);
        },
      },
    ];
  }

  checkUserOrgIntermediate() {
    if (this.isUserOrgIntermediate) {
      if ((this.copyEnabled && !this.showMoredetails) || this.reportEnabled) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
