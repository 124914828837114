import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  SimpleChanges,
} from "@angular/core";

import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { CurrencyPipe } from "@angular/common";

import * as constants from "@app/services/shared/app-constants";

let nextUniqueId = 0;
let typingIdentifier;
const INPUT_INVALID_TYPES = [
  "button",
  "checkbox",
  "file",
  "hidden",
  "image",
  "radio",
  "range",
  "reset",
  "submit",
];

@Component({
  selector: "cc-input-field",
  templateUrl: "./input-field.component.html",
  host: {
    class: "cc-input-field",
    "[id]": "id",
    "[attr.placeholder]": "labelValue",
    "[class.cc-input-field-disabled]": "disabled",
    "[attr.aria-describedby]": "_ariaDescribedby || null",
  },
  styleUrls: ["./_input_field_theme.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CcInputField {
  private _uniqueId = `cc-input-field-${++nextUniqueId}`;
  protected _labelValue = "";
  protected _helphints = "";
  protected _disabled = false;
  protected _required = false;
  protected _type = "text";
  protected _width = "";
  protected _currencyCode;
  protected _placeholder = "";
  public _errorMsg = "";
  protected formattedValue = "";
  cleanValue: any = "";

  protected _inputValueAccessor = "";
  _ariaDescribedby: string;
  transformedCurrency = "";
  @Input() ariaLabelledby;
  @Input() ariaDescribedby;
  @Input() disableDragDrop:boolean = false;

  @Input()
  get width(): string {
    return this._width;
  }
  set width(width: string) {
    if (this._width !== width) {
      this._width = width;
    }
  }
  @Input("symbol")
  get symbol(): string {
    return this._currencyCode;
  }
  set symbol(currencyCode: string) {
    if (this._currencyCode !== currencyCode) {
      this._currencyCode = currencyCode;
      if (this._elementRef.nativeElement.querySelector("input")) {
        this.transformValue();
      }
    }
  }
  @Input() id: string = this._uniqueId;
  get inputId(): string {
    return `${this.id || this._uniqueId}`;
  }
  @Input()
  get labelValue(): string {
    return this._labelValue;
  }
  set labelValue(value: string) {
    this._labelValue = value;
  }
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  @Input("helpText")
  get helpText(): string {
    return this._helphints;
  }
  set helpText(value: string) {
    this._helphints = value;
  }
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  @Input("inputtype")
  get inputtype(): string {
    return this._type;
  }
  set inputtype(value: string) {
    this._type = value;
    this._validateType();
  }
  @Input("placeHolderValue")
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    if (value !== undefined) {
      this._placeholder = value;
    } else {
      this._placeholder = "";
    }
  }
  @Input("value")
  get inputValue(): any {
    return this._inputValueAccessor;
  }
  set inputValue(value: any) {
    if (value !== this.inputValue) {
      this._inputValueAccessor = value;
    }
  }
  constructor(
    private _elementRef: ElementRef,
    private currencyPipe: CurrencyPipe
  ) {}

  @ViewChild("input", { static: true }) inputElement: ElementRef;
  @ViewChild("divEle", { static: true }) divelement: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputValue && this.symbol) {
      if (this._elementRef.nativeElement.querySelector("input")) {
        if (
          this._inputValueAccessor !== null &&
          this._inputValueAccessor.toString().match(constants.numberRegex)
        ) {
          if (
            this.inputValue.toString() !=
            this._elementRef.nativeElement
              .querySelector("input")
              .value.toString()
          ) {
            setTimeout(() => {
              this.transformValue();
            }, 0.5);
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.symbol) {
      this.transformValue();
    }
  }
  protected _validateType() {
    if (INPUT_INVALID_TYPES.indexOf(this._type) > -1) {
      this._errorMsg = `Input type ${this._type} isn't supported.`;
    }
  }
  transformValue() {
    this.formattedValue = this.currencyPipe.transform(
      this.inputValue,
      this.symbol
    );
    if (this.inputValue && this.symbol === "%") {
      this.formattedValue = this.formattedValue?.replace("%", "").trim();
      this._elementRef.nativeElement.querySelector(
        "input"
      ).value = `${this.formattedValue}%`;
    } else {
      this._elementRef.nativeElement.querySelector("input").value =
        this.formattedValue;
    }
  }

  preventDrag(event:DragEvent){
    if(this.disableDragDrop){
      event.preventDefault();
    }
  }

  preventDrop(event:DragEvent){
    if(this.disableDragDrop){
      event.preventDefault();
    }
  }

  formatCurrency(event) {
    if (event.target.value.match(constants.numberRegex)) {
      if (event.target.value !== this.convertCurrency(event.target.value)) {
        const keyevent = new KeyboardEvent("keyup", {
          bubbles: true,
          cancelable: true,
          shiftKey: false,
        });
        const inputElement =
          this._elementRef.nativeElement.querySelector("input");
        inputElement.value = this.convertCurrency(event.target.value);
        inputElement.dispatchEvent(keyevent);
      }
      this.transformValue();
    }
  }

  stopTimer(event) {
    this.cleanValue = event.target.value.replace(/[^\d.]/gm, "");
    if (!this.isNumber(event)) {
      this.cleanValue = event.target.value.replace(/[^\d.]/gm, "");
      event.target.value = this.cleanValue;
      event.preventDefault();
    } else {
      clearTimeout(typingIdentifier);
    }
  }
  isNumber(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;

    if (
      charCode > 31 &&
      charCode != 8 &&
      charCode != 0 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105) &&
      evt.charCode != 46 &&
      charCode != 190 &&
      charCode != 9 &&
      charCode != 110
    ) {
      return false;
    } else if (evt.shiftKey) {
      return false;
    } else if (charCode == 190 && evt.target.value.includes(".")) {
      return false;
    }
    return true;
  }

  removeCurrency() {
    this._elementRef.nativeElement.querySelector("input").value =
      this._elementRef.nativeElement
        .querySelector("input")
        .value.replace(/[^\d.]/gm, "");
    this._elementRef.nativeElement.querySelector("input").select();
  }
  convertCurrency(value) {
    return this.currencyPipe
      .transform(value, this.symbol)
      .replace(/[^\d.]/gm, "");
  }
  checkforValue() {
    if (this.placeholder !== undefined) {
      return this.placeholder;
    } else {
      return "";
    }
  }
}
